/** блок адаптации **/
import './common/adapterFromOldStyle'
/** блок адаптации **/
import {eventEmitter, lang, sessid, esputnikTracker} from "./common/baseData";

import './catalog/style.css';
import './notFound/style.css';

import React, {lazy, Suspense} from 'react'
import ReactDOM from "react-dom";
import ViewedProductsSlider from './components/viewedProductsSlider/ViewedProductsSlider'

eventEmitter.on('EsputnikLoaded', () => {
	esputnikTracker.NotFound();
});

// Просмотренные твоары
new ViewedProductsSlider().init()
