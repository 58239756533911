import Swiper from 'swiper'
import 'swiper/swiper-bundle.css'
import './ViewedProductsSlider.css'

class ViewedProductsSlider {
  init () {
    let viewedProductsWrapper = document.querySelector('.viewed_products_js')

    if (!(viewedProductsWrapper instanceof HTMLElement)) return

    let container = viewedProductsWrapper.querySelector('.swiper-container')
    let pagination = viewedProductsWrapper.querySelector('.swiper-pagination')

    const viewedProductsSwiper = new Swiper(
      container, {
        slidesPerView: 2.3,
        breakpoints: {
          768: { slidesPerView: 4 },
          1024: { slidesPerView: 5 },
          1366: { slidesPerView: 6 },
          1900: { slidesPerView: 8 }
        },
        navigation: {
          nextEl: viewedProductsWrapper.querySelector('.swiper-button-next'),
          prevEl: viewedProductsWrapper.querySelector('.swiper-button-prev'),
        }
      })
  }
}

export default ViewedProductsSlider