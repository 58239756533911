import React, {lazy, Suspense} from 'react';
import {createRoot} from 'react-dom/client';
import './baseData';
import {
  ApplicationContextProvider
} from '../Componentsv2/Context/ApplicationContextProvider';
import Header from '../Componentsv2/Layout/Header';
import Footer from '../Componentsv2/Layout/Footer';

import './style.css';
import '../components/breadcrumbs/Breadcrumbs.css';

const headerRoot = createRoot(document.getElementById('header-root'));
const footerRoot = createRoot(document.getElementById('footer-root'));

// legacy логика определения страницы
let page = undefined;

if (document.body.classList.contains('product-page')) {
  page = 'Product';
}

headerRoot.render(
  <ApplicationContextProvider>
    <Header page={page}/>
  </ApplicationContextProvider>
);
footerRoot.render(
  <ApplicationContextProvider>
    <Footer/>
  </ApplicationContextProvider>);
